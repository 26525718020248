<template>
  <div class="container-fluid" id="public-profile">
    <div v-if="user != null">
      <!-- Profile-->
      <profile-info></profile-info>

      <!-- User Created Contents -->
      <hr>
      <created-contents></created-contents>
    </div>

    <div v-else>
      <no-content v-if="!downloading">
        <h3>No User Found!</h3>
      </no-content>
    </div>
      <!-- Loading -->
    <loading :active.sync="downloading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader :text="'Loading User Profile...'" />
    </loading>
  </div>
</template>

<script>
  const ProfileInfo = () =>
  import('@/components/user/public-profile/ProfileInfo.vue');
  const CreatedContents = () =>
  import('@/components/user/public-profile/CreatedContents.vue');
  const NoContent = () => import('@/components/user/common/NoContent');
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapActions } from 'vuex';

  export default {
    name : 'PublicProfile',
    data() {
      return {
        user        : null,
        downloading : false,
      }
    },
    components : {
      ProfileInfo,
      CreatedContents,
      NoContent,
      Loading,
      RainbowLoader,
    },
    methods : {
      ...mapActions({
        'getIssueCategories' : 'contentReporting/getIssueCategories',
      }),

      /**
       * Get User Data
       * @param userId
       */
      getUser(userId) {
        this.downloading = true;
        this.$http.get('/api/user', {
          params : {
            id : userId,
          },
        }).then(response => {
          this.downloading = false;
          this.user = response.data;
        }).catch(err =>{
          this.downloading = false;
        });
      },
    },
    created() {
      this.getIssueCategories();
    },
    mounted() {
      this.getUser(this.$route.params.userId);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/public-profile";
</style>
